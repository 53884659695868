<template>
  <div class="content-box">
    <div class="content-header" ref="searchBoxRef">
      <el-select clearable v-model="deviceTypeId" class="input" remote :remote-method="getDeviceType"
        @focus="getDeviceType" @change="onDeviceTypeChanged" :filterable="false" placeholder="请选择设备类型">
        <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.deviceName" :value="item.id">
        </el-option>
      </el-select>
      <el-select clearable v-model="deviceClassId" class="input" remote :remote-method="getDeviceClassTypeForFilter"
        @focus="getDeviceClassTypeForFilter" :filterable="false" placeholder="请选择设备分类">
        <el-option v-for="item in deviceClassTypeListForFilter" :key="item.id" :label="item.deviceClassName" :value="item.id">
        </el-option>
      </el-select>
      <el-input
        v-model="presCategoryName"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入产品分类名称"
      ></el-input>
      <el-input
        :value="qrCodeName"
        @input="changeValue($event, 'qrCodeName')"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入产品名称"
      ></el-input>
      <el-input
        v-model="code"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入产品编码"
      ></el-input>
      <el-input
        v-model="remark"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入产品备注"
      ></el-input>
      <el-input
        v-model="storeName"
        maxlength="50"
        class="input"
        clearable
        placeholder="请输入关联门店"
      ></el-input>
      <el-button type="plain" class="cotent-btn" @click="handleSearch">搜索</el-button>
      <el-button type="plain" class="cotent-btn-big" icon="el-icon-plus" @click="handleEdit">新增产品二维码</el-button>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
        v-loading="loading" :cell-style="cellStyle">

        <el-table-column prop="id" label="序号">
        </el-table-column>
        <el-table-column label="产品分类名称" min-width="120">
          <template slot-scope="scope">
            <div class="label-wrap">{{ scope.row.presCategoryName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="产品名称" min-width="150">
          <template slot-scope="scope">
            <div class="label-wrap">{{ scope.row.qrCodeName }}</div>
          </template>
        </el-table-column>

        <el-table-column min-width="120" prop="merchantName" :formatter="formatColumn" label="品牌"></el-table-column>
        <el-table-column min-width="100" prop="deviceTypeName" :formatter="formatColumn" label="设备类型"></el-table-column>
        <el-table-column min-width="120" prop="deviceClassName" :formatter="formatColumn" label="设备分类"></el-table-column>
        <el-table-column prop="storeName" label="关联门店" min-width="160">
          <template #default="{row}">
            <el-tooltip style="margin-left: 4px;" placement="top" effect="dark" :content="row.storeName" v-ak-tooltip-auto-show>
                <span class="ellipsis-text-2">{{row.storeName || ""}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="codeNumber" label="编码数量">
        </el-table-column>
        <el-table-column label="有效编码管理" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDownload(scope.row)">下载二维码</el-button>
            <el-button type="text" @click="handleCode(scope.row)">下载编码</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="downloadTime" label="最后下载时间" min-width="160">
        </el-table-column>
        <el-table-column prop="createdBy" label="操作人">
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template v-if="scope.row.remark" slot-scope="scope">
            <el-popover
              placement="top-end"
              width="250"
              trigger="hover">
              <p>{{ scope.row.remark }}</p>
              <div class="label-wrap c-mouse" slot="reference">{{ scope.row.remark }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="160">
        </el-table-column>
        <el-table-column prop="action" label="操作" fixed="right">
          <template #default="{row}">
              <el-button type="text" @click="bindStore(row)">关联门店</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>

      <el-dialog title="新增产品二维码" :visible.sync="dialogVisible" width="460px" :before-close="dialogClose" :close-on-click-modal="false">
        <div class="edit-box">
          <el-form label-position="left" label-width="100px" :model="formData" ref="validateForm" :rules="rules">
            <el-form-item label="品牌:" prop="merchantId">
              <el-select clearable v-model="formData.merchantId" class="dialogInput" remote @change="onFormMerchantChanged"
                :remote-method="getAllMerchants" @focus="getAllMerchants" :filterable="false" placeholder="请选择品牌">
                <el-option v-for="item in allMerchantList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备类型:" prop="deviceTypeId">
              <el-select clearable v-model="formData.deviceTypeId" class="dialogInput" remote @change="onFormDeviceTypeChanged"
                :remote-method="getDeviceType" @focus="getDeviceType" :filterable="false" placeholder="请选择设备类型">
                <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.deviceName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备分类:" prop="deviceClassId">
              <el-select clearable v-model="formData.deviceClassId" class="dialogInput" remote @change="onDeviceClassChanged"
                :remote-method="getDeviceClassType" @focus="getDeviceClassType" :filterable="false" placeholder="请选择设备分类">
                <el-option v-for="item in deviceClassTypeList" :key="item.id" :label="item.deviceClassName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品类型:" prop="presTypeId">
              <el-select v-model="formData.presTypeId" class="dialogInput" remote
                :filterable="false" placeholder="请选择产品类型">
                <el-option v-for="item in presTypeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品分类:" prop="presCategoryId">
              <el-select clearable v-model="formData.presCategoryId" class="dialogInput" remote
                @change="presCategoryIdChange"
                :remote-method="getPresCategory" @focus="getPresCategory" :filterable="false" placeholder="请选择产品类型">
                <el-option v-for="item in presCategoryList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品名称:" prop="qrCodeId">
              <el-select clearable v-model="formData.qrCodeId" class="dialogInput" @focus="getProductNameOptions" :filterable="true" placeholder="请选择产品类型">
                <el-option
                    v-for="item in productNameOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
              <p class="upload-img-tip">产品名称只用于使用产品名称外显，不做调理类使用校验</p>
            </el-form-item>
            <el-form-item label="编码数量:" prop="codeNumber">
              <el-input-number v-model.number="formData.codeNumber" class="dialogInput" :min="0" :max="999999" :precision="0"
                placeholder="输入要创建编码的数量"></el-input-number>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input type="textarea" v-model="formData.remark" class="dialogInput" placeholder="请输入备注" maxlength="64">
              </el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button type="primary" @click="dialogConfirm">提交</el-button>
          </div>
        </div>
      </el-dialog>

      <ProductListBindStore @syncList="getData" :visible.sync="bindStoreVisible" :bindStoreProductInfo="bindStoreProductInfo" />
    </div>
  </div>
</template>

<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";


import {
  productList,
  saveProduct,
  download,
  downloadCode
} from "@/api/product";
import {
  deviceList
} from "@/api/device";
import { getMainDeviceTypeList, getSubDeviceTypeList } from "@/api/deviceType";
import { getMerchantList, presCategoryTypeList } from "@/api/prescriptionDb";

// 工具
import debounce from "@/utils/debounce";
import deepCopy from "@/utils/deepCopy";

import ProductListBindStore from "./components/bindStore.vue"
import Resize from "@/mixins/resize";

const initFormData = {
  codeNumber: "",
  merchantId: 0,
  deviceTypeId: "",
  deviceClassId: "",
  presTypeId: 1,
  presCategoryId: "",
  qrCodeId: "",
  qrCodeName: "",
};

@Component({
  components: { ProductListBindStore }
})
export default class StoreList extends Mixins(loading, tablePagination, Resize) {
  storeName = "";
  ownerName = "";
  phone = "";
  code = "";
  remark = "";
  presCategoryName = "";
  tableData = [];
  itemTotal = 0;
  dialogVisible = false;
  formData = {
    codeNumber: "",
    merchantId: 0,
    deviceTypeId: "",
    deviceClassId: "",
    presTypeId: 1,
    presCategoryId: "",
    qrCodeId: "",
    qrCodeName: "",
  };
  deviceList = [];
  deviceTypeId = null;
  deviceClassId = null;
  qrCodeName = null;
  deviceTypeList = [];
  allMerchantList = [{"id": 0, "name": "养身帮"}];
  presTypeList = [{
      "id": 1,
      "name": "项目产品库",
      "categoryType": 0
  }];
  presCategoryList = [];
  deviceClassTypeList = [];
  deviceClassTypeListForFilter = [];

  productNameOptions = [];

  rules = []

  bindStoreVisible = false;
  bindStoreProductInfo = {};

  mounted() {
    this.windowResize();
    this.getData();
    this.rules = {
    deviceTypeId: [
      { required: true, message: '请选择设备类型', trigger: 'change' }
    ],
    merchantId: [
      { required: true, message: '请选择品牌', trigger: 'change' }
    ],
    deviceClassId: [
      { required: true, message: '请选择设备分类', trigger: 'change' }
    ],
    presTypeId: [
      { required: false, message: '请选择产品类型', trigger: 'change' }
    ],
    presCategoryId: [
      { required: true, message: '请选择产品分类', trigger: 'change' }
    ],
    qrCodeId: [{required: !this.formData.merchantId, message: "请选择产品"}],
    codeNumber: [
      { required: true, message: '请输入编码数量', trigger: 'blur' },
      { type: 'number', message: '请输入正整数', trigger: 'blur' },
      {
        validator(rule, value, callback) {
          if (Number.isInteger(Number(value)) && Number(value) > 0) {
            callback();
          } else {
            callback(new Error("请输入正整数"));
          }
        },
        trigger: 'blur',
      }
    ]
  };
  }

  getData() {
    this.showLoading();
    productList({
      deviceTypeId: this.deviceTypeId && this.deviceTypeId > 0 ? this.deviceTypeId : null,
      deviceClassId: this.deviceClassId && this.deviceClassId > 0 ? this.deviceClassId : null,
      qrCodeName: this.qrCodeName ? this.qrCodeName : null,
      presCategoryName: this.presCategoryName ? this.presCategoryName : null,
      code: this.code ? this.code : null,
      remark: this.remark,
      storeName: this.storeName,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
    }).then(res => {
      this.hideLoading();
      this.tableData = res.data.list;
      this.itemTotal = res.data.total;
    }).finally(() => {
      this.hideLoading();
    });
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }

  //替换表格时间格式
  formatColumn(row, column) {
    // 获取单元格数据
    let data = row[column.property];
    return data ? data : (column.property == 'merchantName' ? '养身帮' : '未关联');
  }

  getDeviceType(setDefault = false) {
    getMainDeviceTypeList({}).then((res) => {
      this.deviceTypeList = res.data;
      if (setDefault) {
        this.formData.deviceTypeId = 3;
      }
    });
  }

  onDeviceTypeChanged() {
    this.deviceClassId = null;
    this.deviceClassTypeListForFilter = [];
  }

  onFormMerchantChanged($event) {
    if ($event) {
      this.rules.qrCodeId = [];
    } else {
      this.rules.qrCodeId = [{required: !this.formData.merchantId, message: "请选择产品"}]
    }

    if (!this.formData.deviceTypeId) {
      this.getDeviceType(true);
    }
    this.formData.deviceClassId = "";
    this.formData.presCategoryId = "";
    this.deviceClassTypeList = [];
    this.presCategoryList = [];
    this.formData.qrCodeId = "";
  }

  onFormDeviceTypeChanged() {
    this.formData.deviceClassId = "";
    this.formData.presCategoryId = "";
    this.formData.qrCodeId = "";
    this.deviceClassTypeList = [];
    this.presCategoryList = [];
    this.productNameOptions = [];
  }

  onDeviceClassChanged() {
    this.formData.presCategoryId = "";
    this.formData.qrCodeId = "";
    this.presCategoryList = [];
    this.productNameOptions = [];
  }

  presCategoryIdChange() {
    this.formData.qrCodeId = "";
    this.productNameOptions = [];
  }

  // 查询服务商品牌列表（包含养身帮）
  getAllMerchants() {
    getMerchantList({ pageNum:1, isEnable: 1, pageSize:100000 }).then((res) => {
      this.allMerchantList = [{"id": 0, "name": "养身帮"}, ...res.data.list];
    });
  }

  // 通过品牌方查询产品类型
  getPresType() {
    // 获取全部一级分类
    presCategoryTypeList({ categoryType: 0, parentId: 0 }).then((res) => {
      this.presTypeList = res.data;
    });
  }

  // 通过品牌方、产品类型和设备类型查询产品分类菜单
  getPresCategory() {
    let parentId = this.formData.presTypeId;
    let merchantId = this.formData.merchantId;
    let deviceClassId = this.formData.deviceClassId;
    if (!parentId || !deviceClassId) {
      return;
    }
    presCategoryTypeList({ categoryType: 1, parentId, merchantId, deviceClassId }).then((res) => {
      this.presCategoryList = res.data;
    });
  }

  getProductNameOptions() {
    if (!this.formData.presCategoryId) return;
    const params = {
        categoryType: 2,
        parentId: this.formData.presCategoryId,
        merchantId: this.formData.merchantId || 0,
    }
    presCategoryTypeList(params).then((res) => {
      this.productNameOptions = res.data;
    })
  }

  // 通过设备类型查询设备分类菜单
  getDeviceClassType() {
    let parentId = this.formData.deviceTypeId;
    if (!parentId) {
      return;
    }
    getSubDeviceTypeList({parentId}).then((res) => {
      this.deviceClassTypeList = res.data;
    });
  }

  getDeviceClassTypeForFilter() {
    let parentId = this.deviceTypeId;
    if (!parentId) {
      return;
    }
    getSubDeviceTypeList({parentId}).then((res) => {
      this.deviceClassTypeListForFilter = res.data;
    });
  }

  getDeviceList(str) {
    if (typeof str == 'object') {
      str = '';
    }
    deviceList({
      deviceName: str,
      pageNum: 1,
      pageSize: 9999
    }).then((res) => {
      this.deviceList = res.data.list;
    }).catch(err => { });
  }

  // 头部输入
  changeValue(val, type) {
    this[type] = val;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }
  handleEdit() {
    this.getDeviceType(true);
    this.dialogVisible = true;
  }

  bindStore(row) {
    this.bindStoreVisible = true;
    this.bindStoreProductInfo = {...row}
  }

  handleDownload(row) {
    download({
      productId: row.id
    }).then(res => {
      let data = new Blob([res], { type: "application/zip" });
      let url = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${(new Date()).getTime()}.zip`)//下载文件名称
      link.click();
      window.URL.revokeObjectURL(url);
    }).catch(err => { })
  }

  handleCode(row) {
    downloadCode({
      productId: row.id
    }).then(res => {
      let data = new Blob([res], { type: "application/zip" });
      let url = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${(new Date()).getTime()}.zip`)//下载文件名称
      link.click();
      window.URL.revokeObjectURL(url);
    }).catch(err => { })
  }

  dialogClose() {
    this.formData = deepCopy(initFormData);
    this.$refs['validateForm'].resetFields();
    this.deviceTypeList = [];
    this.allMerchantList = [{"id": 0, "name": "养身帮"}];
    this.presCategoryList = [];
    this.deviceClassTypeList = [];
    this.dialogVisible = false;
    this.getDeviceType(true);
  }

  dialogConfirm() {
    this.$refs['validateForm'].validate((valid) => {
      if (valid) {
        this.showLoading();
        this.formData.deviceTypeName = this.getName(this.deviceTypeList, this.formData.deviceTypeId, 'id', 'deviceName');
        this.formData.createdBy = this.$store.getters.userinfo.username;
        this.formData.remark = this.formData.remark?.trim();
        this.formData.qrCodeName = this.productNameOptions?.find((el) => el.id == this.formData.qrCodeId)?.name;
        for (let presCate of this.presCategoryList) {
          if (presCate.id == this.formData.presCategoryId) {
            this.formData.productName = presCate.name;
            break;
          }
        }
        // 品牌方，如果没有选择产品，就把产品分类的填过来
        if (this.formData.merchantId && !this.formData.qrCodeId) {
          this.formData.qrCodeName = this.formData.productName;
        }
        if (!this.formData.productName || this.formData.productName.length == 0) {
          this.$message.error('数据异常，请选择正确的产品分类');
          return;
        }

        saveProduct(this.formData).then(res => {
          this.dialogClose();
          this.resetPages();
          this.getData();
        }).finally(() => {
          this.hideLoading();
        });
      } else {
        console.log('error submit!!');
        return false;
      }
    })
  }
  getName(target, id, keyId, keyName) {
    let name = ''
    target.forEach(item => {
      if (item[keyId] == id) {
        name = item[keyName]
      }
    })
    return name
  }

}
</script>



<style lang="scss" scoped src="./style.scss">
</style>